<template>
  <Modal :show="showModal" @onClose="closeModal">
    <template #header>
      {{
        selectedSubmission
          ? `${$t("update")} '${selectedSubmission.form.name}'`
          : $t("add-submission")
      }}
    </template>
    <template #body
      ><submission-form @onClose="closeModal" :submission="selectedSubmission"
    /></template>
  </Modal>

  <Loader v-if="loading && list.length === 0" />
  <template v-else>
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div class="container flex flex-row items-center justify-start">
        <div class="flex items-center">
          <div class="mr-2">
            <img src="/icon-title-submissions.svg" class="h-5 w-5" />
          </div>
          <div>
            <h1 class="font-bold text-WADARKBLUE-500 text-base uppercase">
              {{ $t("submissions") }}
            </h1>
          </div>
        </div>
      </div>
    </section>

    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <button
            @click="$router.push('/submissions/new')"
            class="bg-WAORANGE-500 hover:bg-WAORANGE-400 mr-2 px-4 py-2 rounded-lg text-white text-xs uppercase"
          >
            + {{ $t("add-submission") }}
          </button>
        </div>
        <submission-filter />
      </div>
    </section>

    <section class="lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6">
      <submission-table-row />
      <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
        <span>* {{ error }}</span>
      </div>
      <div v-if="filteredSubmissions.length > 0">
        <Submission
          @edit="onEdit"
          @mark-as-unread="markAsUnread"
          v-for="submission in filteredSubmissions"
          :key="submission._id"
          :submission="submission"
        />
      </div>
      <div v-else>
        <h1 class="text-sm text-center text-WADARKBLUE-500 my-10">
          {{ $t("no-data") }}
        </h1>
      </div>
    </section>
  </template>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal.vue";
import SubmissionTableRow from "@/components/submissions/SubmissionTableRow.vue";
import Submission from "@/components/submissions/Submission.vue";
import SubmissionForm from "@/components/submissions/SubmissionForm.vue";
import SubmissionFilter from "@/components/submissions/SubmissionFilter.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    SubmissionFilter,
    SubmissionTableRow,
    Submission,
    SubmissionForm,
    Modal,
    Loader,
  },
  computed: {
    ...mapGetters("submissions", [
      "list",
      "filteredSubmissions",
      "loading",
      "error",
    ]),
    ...mapGetters(["currentUser"]),
    isAdminOrEdit() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.submission?.admin ||
          this.currentUser?.role?.submission?.edit)
      );
    },
  },
  async created() {
    await this.getSubmissions();
  },
  data() {
    return {
      showModal: false,
      selectedSubmission: null,
    };
  },
  methods: {
    ...mapActions("submissions", ["getSubmissions"]),
    ...mapMutations("submissions", ["markAsUnread"]),
    openModal() {
      this.showModal = true;

      // set to null when adding new submission
      this.selectedSubmission = null;
    },

    closeModal() {
      this.showModal = false;
      this.selectedSubmission = null;
    },

    onEdit(submission) {
      this.showModal = true;
      this.selectedSubmission = null;
      this.selectedSubmission = submission;
    },
  },
};
</script>
